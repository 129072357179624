//フォント設定
@mixin baseFont {
	font-family: "YuGothic", "游ゴシック", "Noto Sans JP", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic","メイリオ", Meiryo,sans-serif;
}
@mixin fontLatin {
	font-family: 'Montserrat', sans-serif;
}
@mixin fontMincho {
	font-family: "YuMincho", "Hiragino Mincho ProN", "Yu Mincho", "MS PMincho", serif;
}
@mixin fontLight {
	font-weight: 300;
}
@mixin fontNormal {
	font-weight: 400;
}
@mixin fontMedium {
	font-weight: 600;
}
@mixin fontBold {
	font-weight: 700;
}
// レスポンシブ対応（可変）のフォントサイズ（クライアントによって好みが分かれるので仕様は適宜判断）
//SP
@function get_vw($size, $viewport:375){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}
@mixin fz_vw($font_size:12){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

//PC
@function get_vw_pc($size2, $viewport2:1200){
	$rate2: 100 / $viewport2;
	@return $rate2 * $size2 * 1vw;
  }
  @mixin fz_vw_pc($font_size2:14){
	font-size: $font_size2 * 1px;
	font-size: get_vw_pc($font_size2);
  }

// 内側のボックスサイズ
@mixin innerBase {
	max-width: 1160px;
	width: 100%;
	margin: 0 auto;
}

// リンク設定
@mixin linkBase {
	text-decoration: none;
	transition: all  0.3s;
	color: #1E4E90;
}
@mixin hoverBase {
	opacity: 0.7;
}
@mixin boxSizing {
	box-sizing: border-box;
}


// フェード設定
@mixin fadeBase {
	opacity: 0;
    transition: all 1.5s;
}
@mixin fadeIn {
	opacity: 1;
}


// キーカラー設定
$basefontColor : #292F38;
$mainBlkColor : #292F38;
$pointColor1 : #1E4E90;

