@charset "utf-8";
/* CSS Document */
@import "import.scss";

.main-area {
  padding: 50px 0 80px;
  @media screen and (min-width:769px){
    padding-bottom: 120px;
    padding-top: 80px;
  }
  .box-inner {
    @media screen and (min-width:769px){
      padding: 0 10.8vw;
    }
  }
}

.main-mds {
  text-align: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 30px;
  color: #4d5664;
  @media screen and (min-width:769px){
    font-size: 24px;
  }
}
.top-txt {
  margin-bottom: 30px;
  font-size: 14px;
  text-align: center;
  @media screen and (min-width:769px){
    font-size: 16px;
  }
}
.link {
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  @media screen and (min-width:769px){
    font-size: 16px;
  }
}
